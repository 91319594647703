<template>
  <div class="mx-auto px-4 sm:px-4 lg:px-8">
    <div class="">
      <div class="flex flex-col space-y-4">
        <div class="items-strecth flex justify-end space-x-4">
          <div class="relative flex-initial rounded-md shadow-sm">
            <input
              :value="getWarehouse.data?.attributes?.code"
              type="text"
              name="search"
              disabled
              id="search"
              class="block w-full rounded-md border-gray-300 bg-gray-100 pl-4 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              placeholder="Cari kode atau nama"
            />
          </div>
          <base-select
            :expand="false"
            :shadow="false"
            :options="isPointFilterOptions"
            v-model="stockTable.filter.is_point"
            v-on:change="onFilter"
          />
          <base-search
            v-model="stockTable.filter.search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
          <export-dropdown />
        </div>
        <div class="flex sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full align-middle sm:px-6 lg:px-8">
            <StockTable @detail="onDetail" @page-changed="onPageChange" />
          </div>
        </div>
      </div>

      <stock-detail-modal
        :visible="visibleDetail"
        @close="visibleDetail = false"
        @change-page-stock-trx="onChangePageStockTrx"
      />
      <loading v-if="isLoading"></loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import StockDetailModal from '@/components/stock/StockDetailModal.vue';
import { ScStockColumns } from '@/services/columns.service';
import BaseSelect from '@/components/base/BaseSelect.vue';
import StockTable from '@/components/stock/stock-table.vue';
import ExportDropdown from '@/components/stock/export-stock-dropdown.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Barang',
  components: {
    StockDetailModal,
    BaseSelect,
    StockTable,
    ExportDropdown,
    BaseSearch,
  },
  data() {
    return {
      ScStockColumns,
      visibleDetail: false,
      stockTable: {
        filter: {
          search: null,
          is_point: true,
        },
        page: {
          size: 5,
          number: 1,
        },
      },
      stockTrxTable: {
        page: {
          number: 1,
          size: 5,
        },
      },
      currentDetailStock: null,
    };
  },
  computed: {
    ...mapGetters({
      getStockLoading: 'stocks/getLoading',
      getWarehouseLoading: 'warehouses/getLoading',
      getStockTrxLoading: 'stock_trxes/getLoading',
      getStocks: 'stocks/getStocks',
      getWarehouse: 'warehouses/getWarehouse',
      me: 'auth/getUser',
      getUser: 'auth/getUser',
    }),
    isLoading() {
      return (
        this.getStockLoading ||
        this.getWarehouseLoading ||
        this.getStockTrxLoading
      );
    },
    isPointFilterOptions() {
      return [
        {
          key: 'poin',
          value: true,
          label: 'Barang Berpoin',
        },
        {
          key: 'non-poin',
          value: false,
          label: 'Barang Non Poin',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchStocksByWarehouse: 'stocks/fetchStocksByWarehouse',
      fetchStockById: 'stocks/fetchStockById',
      fetchStockTrxesByStock: 'stock_trxes/fetchStockTrxesByStock',
      fetchWarehouseById: 'warehouses/fetchWarehouseById',
    }),
    loadWarehouseStock({ resetPage } = {}) {
      if (resetPage) {
        this.stockTable.page.number = 1;
      }

      this.fetchStocksByWarehouse({
        sortName: 'product_code',
        sortDirection: 'asc',
        warehouse_id: this.getUser.current_warehouse,
        'filter[search]': this.stockTable.filter.search,
        'filter[is_point]': this.stockTable.filter.is_point,
        'page[size]': this.stockTable.page.size,
        'page[number]': this.stockTable.page.number,
        'fields[stocks]':
          'product_code,product_name,stock_qty,stock_ordered,stock_real,free_qty,free_ordered,free_real',
      });
    },
    async loadStockTrx({ resetPage } = {}) {
      if (resetPage) {
        this.stockTrxTable.page.number = 1;
      }

      await this.fetchStockTrxesByStock({
        stockId: this.currentDetailStock.id,
        params: {
          params: {
            'page[number]': this.stockTrxTable.page.number,
            'page[size]': this.stockTrxTable.page.size,
            sort: '-createdAt',
          },
        },
      });
    },
    onFilter() {
      this.loadWarehouseStock({ resetPage: true });
    },
    onPageChange(page) {
      this.stockTable.page.number = page;

      this.loadWarehouseStock();
    },
    onChangePageStockTrx(page) {
      this.stockTrxTable.page.number = page;

      this.loadStockTrx({ resetPage: false });
    },
    debounceSearch: debounce(function () {
      this.loadWarehouseStock({ resetPage: true });
    }, 300),
    async onDetail(stock) {
      this.currentDetailStock = stock;

      await Promise.all([
        this.fetchStockById({
          id: stock.id,
          params: {
            // 'fields[stocks]': 'product_code,product_name,stock_qty,is_orderable,is_pre_orderable,total_weight,license_status'
          },
        }),
        this.loadStockTrx({ resetPage: true }),
      ]);

      this.visibleDetail = true;
    },
  },
  created() {
    this.fetchWarehouseById({
      id: this.getUser.current_warehouse,
      params: {
        'fields[warehouses]': 'code',
      },
    });
    this.loadWarehouseStock();
  },
};
</script>
